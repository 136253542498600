@import '../../node_modules/modern-normalize/modern-normalize.css';
@import './angular';
@import './colors';
@import './easing';
@import './fonts';
@import './base';
@import './charts';
@import './spinner';
@import './vessel-states';

@import './theme-albis/theme.scss';
@import '../../node_modules/primeng/resources/primeng.min.css';
@import '../../node_modules/primeflex/primeflex.css';
@import  '../../node_modules/ol/ol.css';
@import '../../node_modules/ol-contextmenu/dist/ol-contextmenu.css';
@import '../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';
@import '../assets/fonts/albis-icons/albis-icons.css';
